var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "px-0 mx-0",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center mt-5",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$user, _vm$user2;
        var hover = _ref.hover;
        return [_c('v-avatar', {
          attrs: {
            "size": "130"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              _vm.profileImage = true;
            }
          }
        }, [_c('ProfileImage', {
          attrs: {
            "image": (_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.image,
            "size": 130,
            "className": "img-fluid align-self-center rounded-circle",
            "alt": (_vm$user2 = _vm.user) === null || _vm$user2 === void 0 ? void 0 : _vm$user2.name
          }
        }), _c('v-fade-transition', [hover && !_vm.loading ? _c('v-overlay', {
          attrs: {
            "absolute": "",
            "color": "black"
          }
        }, [!_vm.loading ? _c('v-btn', {
          attrs: {
            "icon": "",
            "loading": _vm.loading
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              _vm.profileDialog = true;
            }
          }
        }, [!_vm.loading ? _c('v-icon', {
          staticStyle: {
            "cursor": "pointer"
          }
        }, [_vm._v(" edit ")]) : _vm._e()], 1) : _vm._e()], 1) : _vm._e(), _vm.loading ? _c('v-overlay', {
          attrs: {
            "absolute": "",
            "color": "black"
          }
        }, [_vm.loading ? _c('v-btn', {
          attrs: {
            "icon": "",
            "loading": _vm.loading
          }
        }) : _vm._e()], 1) : _vm._e()], 1)], 1)];
      }
    }])
  }), !!_vm.user && _vm.user.image ? _c('div', {
    staticClass: "cursor-pointer mt-4",
    on: {
      "click": _vm.onDeletedImage
    }
  }, [_vm._v(" Remove ")]) : _vm._e()], 1), _c('v-col', {
    staticClass: "ml-4",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|max:20|min:3',
      expression: "'required|max:20|min:3'"
    }],
    attrs: {
      "counter": "20",
      "id": "user_name_field",
      "placeholder": "Your name",
      "filled": ""
    },
    on: {
      "click:append": function clickAppend($event) {
        _vm.show = !_vm.show;
      }
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }, 'v-text-field', _vm.veeValidate('Name', 'Name'), false)), [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|email',
      expression: "'required|email'"
    }],
    attrs: {
      "id": "user_email_field",
      "placeholder": "Your email address",
      "filled": ""
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }, 'v-text-field', _vm.veeValidate('Email', 'Email'), false))], _c('v-checkbox', {
    attrs: {
      "label": "Enable push notification"
    },
    model: {
      value: _vm.pushNotification,
      callback: function callback($$v) {
        _vm.pushNotification = $$v;
      },
      expression: "pushNotification"
    }
  })], 2), _c('v-col', {
    staticClass: "pb-0 text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "mx-auto mb-3 text-transform-none",
    attrs: {
      "loading": _vm.busy,
      "depressed": "",
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark,
      "id": "profile-edit-save",
      "disabled": _vm.errors.any()
    },
    on: {
      "click": _vm.UpdateProfileInfo
    }
  }, [_vm._v(" Save ")])], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "loading": _vm.busy,
      "depressed": "",
      "color": "error",
      "text": ""
    },
    on: {
      "click": _vm.openAccountDelete
    }
  }, [_vm._v(" Delete Account ")])], 1), _c('Modal', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.mdAndUp ? '600' : ''
    },
    on: {
      "close": function close($event) {
        _vm.profileDialog = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.profileDialog ? _c('image-upload', {
          attrs: {
            "enable-resize": true,
            "hide-overlay": "",
            "profile": ""
          },
          on: {
            "close": function close($event) {
              _vm.profileDialog = false;
            },
            "cropped-image": _vm.updateProfileImage
          },
          model: {
            value: _vm.profileImage,
            callback: function callback($$v) {
              _vm.profileImage = $$v;
            },
            expression: "profileImage"
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.profileDialog,
      callback: function callback($$v) {
        _vm.profileDialog = $$v;
      },
      expression: "profileDialog"
    }
  }), _c('Modal', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.mdAndUp ? '600' : ''
    },
    on: {
      "close": function close($event) {
        _vm.accountDeleteModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', [_c('v-card-title', [_c('div', {
          staticClass: "semi-bold subListFont"
        }, [_vm._v("Confirm Deletion")])]), _c('v-card-text', [!_vm.verificationCode ? [!_vm.showReason ? _c('v-row', [_c('v-col', {
          staticClass: "subListFont",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Please take a moment to review what will happen when your account is deleted as this action is permanent and not reversible. "), _c('br'), _c('br'), _vm._v(" Any single-member Teams that you own will be placed in a queue to be permanently deleted after 24 hours. You will be immediately removed from and lose access to all other Teams. ")]), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('a', {
          staticClass: "primary--text cursor-pointer",
          attrs: {
            "href": "https://help.testapp.io/request-account-deletion/",
            "target": "_blank"
          }
        }, [_vm._v("Learn more...")])])], 1) : _c('v-row', [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('v-textarea', _vm._b({
          directives: [{
            name: "validate",
            rawName: "v-validate",
            value: 'required|max:600',
            expression: "'required|max:600'"
          }],
          attrs: {
            "counter": "600",
            "placeholder": "Reason",
            "filled": ""
          },
          model: {
            value: _vm.deleteReason,
            callback: function callback($$v) {
              _vm.deleteReason = $$v;
            },
            expression: "deleteReason"
          }
        }, 'v-textarea', _vm.veeValidate('Reason', 'Reason'), false))], 1)], 1)] : _c('verification-code-checker', {
          attrs: {
            "action": "delete_account",
            "email": _vm.user.email,
            "from-delete-account": ""
          },
          on: {
            "next": _vm.onSIGNOUT
          }
        })], 2), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "outlined": "",
            "color": "success"
          },
          on: {
            "click": _vm.openSchedule
          }
        }, [_vm._v(" Contact us ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "disabled": _vm.deleteLoader,
            "outlined": ""
          },
          on: {
            "click": _vm.cancelDeleteModal
          }
        }, [_vm._v(" Cancel ")]), !_vm.verificationCode ? _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "loading": _vm.deleteLoader,
            "outlined": "",
            "color": "error"
          },
          on: {
            "click": _vm.confirmDeleteAccount
          }
        }, [_vm._v(" Confirm ")]) : _vm._e()], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.accountDeleteModal,
      callback: function callback($$v) {
        _vm.accountDeleteModal = $$v;
      },
      expression: "accountDeleteModal"
    }
  }), _c('Modal', {
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', {
          staticClass: "pa-4"
        }, [_c('v-card-title', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              _vm.showVerificationCode = false;
            }
          }
        }, [_c('v-icon', {
          domProps: {
            "textContent": _vm._s('mdi-close')
          }
        })], 1)], 1), _c('verification-code-checker', {
          attrs: {
            "action": "change_email",
            "email": _vm.email,
            "from-delete-account": ""
          },
          on: {
            "next": _vm.emailUpdate
          }
        })], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showVerificationCode,
      callback: function callback($$v) {
        _vm.showVerificationCode = $$v;
      },
      expression: "showVerificationCode"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }