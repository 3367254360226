<template>
  <v-row justify="center" class="px-0 mx-0">
    <v-col class="text-center mt-5" cols="12">
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-avatar @click.stop="profileImage = true" size="130">
            <ProfileImage
              :image="user?.image"
              :size="130"
              className="img-fluid align-self-center rounded-circle"
              :alt="user?.name"
            />
            <v-fade-transition>
              <v-overlay v-if="hover && !loading" absolute color="black">
                <v-btn
                  @click.stop="profileDialog = true"
                  v-if="!loading"
                  icon
                  :loading="loading"
                >
                  <v-icon style="cursor: pointer" v-if="!loading">
                    edit
                  </v-icon>
                </v-btn>
              </v-overlay>
              <v-overlay absolute color="black" v-if="loading">
                <v-btn v-if="loading" icon :loading="loading" />
              </v-overlay>
            </v-fade-transition>
          </v-avatar>
        </template>
      </v-hover>
      <div
        class="cursor-pointer mt-4"
        @click="onDeletedImage"
        v-if="!!user && user.image"
      >
        Remove
      </div>
    </v-col>
    <v-col class="ml-4" cols="12" md="8">
      <v-text-field
        v-model="name"
        v-validate="'required|max:20|min:3'"
        counter="20"
        id="user_name_field"
        v-bind="veeValidate('Name', 'Name')"
        placeholder="Your name"
        filled
        @click:append="show = !show"
      >
      </v-text-field>
      <template>
        <v-text-field
          v-validate="'required|email'"
          v-bind="veeValidate('Email', 'Email')"
          id="user_email_field"
          v-model="email"
          placeholder="Your email address"
          filled
        >
        </v-text-field>
      </template>
      <v-checkbox
        v-model="pushNotification"
        label="Enable push notification"
      ></v-checkbox>
    </v-col>
    <v-col cols="12" class="pb-0 text-center">
      <v-btn
        :loading="busy"
        @click="UpdateProfileInfo"
        depressed
        color="primary"
        :outlined="$vuetify.theme.dark"
        class="mx-auto mb-3 text-transform-none"
        id="profile-edit-save"
        :disabled="errors.any()"
      >
        Save
      </v-btn>
    </v-col>
    <v-col class="text-right" cols="12">
      <v-btn
        :loading="busy"
        @click="openAccountDelete"
        depressed
        class="text-transform-none"
        color="error"
        text
      >
        Delete Account
      </v-btn>
    </v-col>
    <Modal
      :width="$vuetify.breakpoint.mdAndUp ? '600' : ''"
      v-model="profileDialog"
      @close="profileDialog = false"
    >
      <template #card>
        <image-upload
          v-if="profileDialog"
          v-model="profileImage"
          :enable-resize="true"
          hide-overlay
          profile
          @close="profileDialog = false"
          @cropped-image="updateProfileImage"
        ></image-upload>
      </template>
    </Modal>
    <Modal
      :width="$vuetify.breakpoint.mdAndUp ? '600' : ''"
      v-model="accountDeleteModal"
      @close="accountDeleteModal = false"
    >
      <template #card>
        <v-card>
          <v-card-title>
            <div class="semi-bold subListFont">Confirm Deletion</div>
          </v-card-title>
          <v-card-text>
            <template v-if="!verificationCode">
              <v-row v-if="!showReason">
                <v-col class="subListFont" cols="12">
                  Please take a moment to review what will happen when your
                  account is deleted as this action is permanent and not
                  reversible.
                  <br /><br />
                  Any single-member Teams that you own will be placed in a queue
                  to be permanently deleted after 24 hours. You will be
                  immediately removed from and lose access to all other Teams.
                </v-col>
                <v-col cols="12">
                  <a
                    class="primary--text cursor-pointer"
                    href="https://help.testapp.io/request-account-deletion/"
                    target="_blank"
                    >Learn more...</a
                  >
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12">
                  <v-textarea
                    v-model="deleteReason"
                    counter="600"
                    placeholder="Reason"
                    filled
                    v-validate="'required|max:600'"
                    v-bind="veeValidate('Reason', 'Reason')"
                  ></v-textarea>
                </v-col>
              </v-row>
            </template>
            <verification-code-checker
              v-else
              action="delete_account"
              :email="user.email"
              from-delete-account
              @next="onSIGNOUT"
            ></verification-code-checker>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="openSchedule"
              outlined
              color="success"
              class="text-transform-none"
            >
              Contact us
            </v-btn>
            <v-btn
              :disabled="deleteLoader"
              @click="cancelDeleteModal"
              outlined
              class="text-transform-none"
            >
              Cancel
            </v-btn>
            <v-btn
              v-if="!verificationCode"
              :loading="deleteLoader"
              @click="confirmDeleteAccount"
              outlined
              color="error"
              class="text-transform-none"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </Modal>
    <Modal v-model="showVerificationCode">
      <template #card>
        <v-card class="pa-4">
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn @click="showVerificationCode = false" icon>
              <v-icon v-text="'mdi-close'"></v-icon>
            </v-btn>
          </v-card-title>
          <verification-code-checker
            action="change_email"
            :email="email"
            from-delete-account
            @next="emailUpdate"
          ></verification-code-checker>
        </v-card>
      </template>
    </Modal>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import veeValidate from "@/mixins/veeValidate";
import {
  CLEAR_STORE,
  DELETE_PROFILE,
  DELETE_PROFILE_IMAGE,
  PURGE_AUTH,
  UPDATE_PROFILE,
  UPDATE_PROFILE_IMAGE,
} from "@/store/users/auth.module";
import ImageUpload from "@/view/components/Common/ImageUpload";
import { destroyToken } from "@/core/services/jwt.service";
import { segmentEvents } from "@/consts/segmentEventConst";
import { mapGetters } from "vuex";
import VerificationCodeChecker from "@/view/components/Common/VerificationCodeChecker";

export default {
  mixins: [veeValidate],
  components: { VerificationCodeChecker, ImageUpload },
  data() {
    return {
      profileDialog: false,
      profileImage: "",
      busy: false,
      showReason: false,
      deleteReason: "",
      deleteLoader: false,
      verificationCode: false,
      accountDeleteModal: false,
      showVerificationCode: false,
      name: "",
      email: "",
      pushNotification: true,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      getTeamId: "getTeamId",
    }),
    ...mapState({
      user: (state) => state?.auth?.user,
    }),
  },
  created() {
    this.name = this.user?.name;
    this.email = this.user?.email;
  },
  methods: {
    ...mapMutations({
      updateUserEmail: "updateUserEmail",
    }),
    onDeletedImage() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(DELETE_PROFILE_IMAGE)
        .then((response) => {
          this.notifyUserMessage({
            message:
              response.message || "Successfully deleted your profile image",
          });
          this.loading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(
            err.message || "You are already using the default image"
          );
          this.loading = false;
        });
    },
    emailUpdate() {
      this.showVerificationCode = false;
      this.updateUserEmail(this.email);
      this.notifyUserMessage({
        message: "Successfully updated your email address",
      });
    },
    updateProfileImage(event) {
      this.menu = true;
      if (this.loading) return;
      this.loading = true;
      // let image = event.target.files[0];
      let formData = new FormData();
      formData.append("image", event);
      this.$store
        .dispatch(UPDATE_PROFILE_IMAGE, formData)
        .then((response) => {
          this.$emit("close");
          this.notifyUserMessage({
            message: response.message || "Successfully updated the image",
          });
          this.loading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(
            err.message || "Something went wrong, please try again"
          );
          this.loading = false;
        });
    },
    async UpdateProfileInfo() {
      if (await this.validateAllFields()) {
        if (this.busy) return;
        this.busy = true;
        const dataToPost = {
          name: this.name,
          email: this.email,
          push_notification: this.pushNotification,
        };
        this.$store
          .dispatch(UPDATE_PROFILE, dataToPost)
          .then((response) => {
            if (this.user.email !== this.email) {
              this.showVerificationCode = true;
            }
            this.$emit("close");
            this.profileDialog = false;
            this.notifyUserMessage({
              message: response.message || "Successfully updated the info",
            });
            this.busy = false;
            this.activeTab = "user";
            this.busy = false;
          })
          .catch((err) => {
            this.notifyErrorMessage(
              err.message || "Something went wrong, please try again"
            );
            this.busy = false;
          });
      }
    },
    openSchedule() {
      this.logEvent(segmentEvents.SCHEDULE_CALL, {
        from: "Delete account modal",
      });
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/omar-massad/improving-our-services",
      });
    },
    async confirmDeleteAccount() {
      if (!this.showReason) {
        this.logEvent(segmentEvents.CLICKED_ON_CONFIRM, {
          from: "Delete account modal",
          action: "Reason text area",
        });
        this.showReason = true;
        return;
      }
      if (await this.validateAllFields()) {
        this.logEvent(segmentEvents.INITIATE_DELETE_ACCOUNT);
        if (this.deleteLoader) return;
        this.deleteLoader = true;
        this.$store
          .dispatch(DELETE_PROFILE, { reason: this.deleteReason })
          .then((response) => {
            this.verificationCode = true;
            this.showReason = false;
            this.deleteLoader = false;
          })
          .catch((err) => {
            this.notifyErrorMessage(err.message);
            this.deleteLoader = false;
          });
      }
    },
    openAccountDelete() {
      this.logEvent(segmentEvents.DELETE_ACCOUNT, {
        from: "Edit profile modal",
      });
      this.accountDeleteModal = true;
    },
    onSIGNOUT(detail) {
      destroyToken();
      this.notifyUserMessage(detail?.message);
      this.$router.push({ name: "signin" }).catch(() => {});
      this.$store.commit(PURGE_AUTH);
      this.$store.dispatch(CLEAR_STORE);
    },
    cancelDeleteModal() {
      this.logEvent(segmentEvents.CLICKED_ON_CANCEL, {
        from: "delete account modal",
      });
      this.accountDeleteModal = false;
      this.showReason = false;
    },
  },
};
</script>
